export const clinics = [
    {
      id: 'kukatpally',
      name: "Unidental - Kukatpally, Hyderabad",
      address: "2nd Floor, Shree Krishna Diagnostics, Beside Kalamandir, KPHB Main Rd, Kukatpally, Hyderabad, Telangana 500072, India",
      phone: "+91 6305971445",
      email: "unidentalindia@gmail.com",
      whatsapp: "+916305971445", 
      location: { lat: 17.49606, lng: 78.40053 },
    },
    {
      id: 'anantapur',
      name: "Unidental - Anantapur",
      address: "1-1348 Near crescent function Hall, 80 Feet Rd, opp. to Sakshi, Anantapur, Andhra Pradesh 515004, India",
      phone: "+91 7075890089",
      email: "unidentalindia@gmail.com",
      whatsapp: "+917075890089", 
      location: { lat: 14.66906, lng: 77.58586 },
    },
    {
      id: 'goa',
      name: "Unidental - Goa",
      address: "Manipal Hospitals Goa, Dr E Borges Rd, Dona Paula, Durgavado, Goa 403004, India",
      phone: "+91 8326632500",
      email: "unidentalindia@gmail.com",
      whatsapp: "+918326632500", 
      location: { lat: 15.46027, lng: 73.81327 },
    },
  ];
  