// LocationLanding.jsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import { Calendar, ArrowRight, MapPin, Phone, Clock } from 'lucide-react';
import Services from '../../components/Services/SymptomsAndTreatmentsScrollable';
import AppointmentFormManager from '../../components/AppointmentFormManager/AppointmentFormManager';
import AppointmentForm from '../../components/AppointmentForm/AppointmentForm';
import './LocationLanding.scss';
import emailjs from 'emailjs-com';
import img from '../../assets/images/hospital-image.jpeg';
import Captcha from '../../components/Captcha/Captcha';
import Badges from '../../components/Badges/Badges';
import PromoBanner from '../../components/PromoBanner/PromoBanner';
import { clinics } from './clinicData';

const LocationLanding = () => {
  const {location}  = useParams();
  const servicesRef = React.useRef(null);
  const reviewsRef = React.useRef(null);
  const ctaRef = React.useRef(null);
  const isServicesInView = useInView(servicesRef, { once: true, margin: "-100px" });
  const isReviewsInView = useInView(reviewsRef, { once: true, margin: "-100px" });
  const isCtaInView = useInView(ctaRef, { once: true, margin: "-100px" });
  const currentClinic = clinics.find(clinic => clinic.name.includes(location)) || clinics[0];

  const { openForm, closeForm, isFormOpen, selectedLocation } = AppointmentFormManager();

  const handleAppointmentClick = () => {
    openForm(location);
  };


  const locationData = {
    Hyderabad: {
      name: 'Hyderabad',
      description: "Welcome to Unidental Hyderabad, where modern dental care meets Hyderabad's historic charm. Our state-of-the-art clinic, located in the heart of the city, combines advanced dental technology with the warm, friendly atmosphere that Hyderabad is known for. We're proud to serve our diverse community with comprehensive dental care that puts your comfort first.",
      uniquePoints: [
        'Central location near Hyderabad ONE',
        'Multi-lingual staff speaking English, Telugu, and Hindi',
        'Extended evening and weekend hours',
        'State-of-the-art digital scanning technology'
      ],
      image: img,
      reviews: {
        title: "What Our Hyderabad Patients Say About Us",
        items: [
          {
            text: "I found my new go-to dental clinic in Hyderabad! The team is professional, and I felt so comfortable. Highly recommend!",
            author: "Priya S."
          },
          {
            text: "Great service and a wonderful experience from start to finish. The Unidental Hyderabad team is excellent!",
            author: "Rajiv K."
          }
        ]
      }
    },
    Goa: {
      name: 'Goa',
      description: "Welcome to Unidental Goa, where modern dental care meets the serene beauty of Goa. Our clinic, located in the heart of the city, offers state-of-the-art dental services in a relaxing and comfortable environment. Whether you're a local resident or a tourist, we provide comprehensive dental care tailored to your needs.",
      uniquePoints: [
        'Prime location in the heart of Goa',
        'Multi-lingual staff speaking English, Konkani, and Hindi',
        'Flexible hours to accommodate your schedule',
        'Advanced dental technology for precise treatments'
      ],
      image: img,
      reviews: {
        title: "What Our Goa Patients Say About Us",
        items: [
          {
            text: "Unidental Goa is the best dental clinic I've visited. The staff is friendly, and the service is top-notch. Highly recommend!",
            author: "Maria L."
          },
          {
            text: "I had a great experience at Unidental Goa. The dentist was very professional, and the clinic is very clean and modern.",
            author: "John D."
          }
        ]
      }
    },
    Anantapur: {
      name: 'Anantapur',
      description: "Welcome to Unidental Anantapur, where we bring modern dental care to the heart of Anantapur. Our clinic is equipped with the latest dental technology, and our team is dedicated to providing personalized care in a warm and welcoming environment. We strive to make every visit comfortable and stress-free.",
      uniquePoints: [
        'Convenient location in the city center',
        'Multi-lingual staff speaking English, Telugu, and Kannada',
        'Affordable dental care for the entire family',
        'State-of-the-art facilities for all dental needs'
      ],
      image: img,
      reviews: {
        title: "What Our Anantapur Patients Say About Us",
        items: [
          {
            text: "Unidental Anantapur is a fantastic clinic. The staff is very caring, and the dentist explained everything clearly. Highly recommend!",
            author: "Ramesh P."
          },
          {
            text: "I had a great experience at Unidental Anantapur. The clinic is clean, and the service is excellent. I will definitely return.",
            author: "Sita R."
          }
        ]
      }
    }
  };

  const [formData, setFormData] = useState({
    location: location, // Default location
    name: '',
    email: '',
    phone: '',
    date: '',
    message: '',
    agreement: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const validateField = (name, value) => {
    let error = '';
    if (!value || value.trim() === '') {
      switch (name) {
        case 'name':
          return 'Name is required';
        case 'email':
          return 'Email is required';
        case 'phone':
          return 'Phone number is required';
        default:
          return '';
      }
    }
    switch (name) {
      case 'email':
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          error = 'Invalid email address';
        }
        break;
      case 'phone':
        if (!/^[6-9]\d{9}$/.test(value)) {
          error = 'Invalid phone number';
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    if (type !== 'checkbox') {
      const error = validateField(name, value);
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    Object.keys(formData).forEach((key) => {
      if (key !== 'message' && key !== 'agreement') {
        const error = validateField(key, formData[key]);
        if (error) {
          newErrors[key] = error;
          isValid = false;
        }
      }
    });
    setErrors(newErrors);
    return isValid;
  };

  const sendEmail = async () => {
    try {
      const result = await emailjs.send(
        'service_lffniaa', // Replace with your EmailJS service ID
        'template_5yrtjei', // Replace with your EmailJS template ID
        {
          location: formData.location,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          date: formData.date,
          message: formData.message,
        },
        'YYf7gTHlbO0musIlg' // Replace with your EmailJS user ID
      );

      console.log('Email sent:', result.text);
      setIsSuccess(true);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (validateForm() && formData.agreement) {
      setIsSubmitting(true);
      try {
        await sendEmail();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCaptchaValidate = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  const currentLocationData = locationData[location];


  return (
    <div className="page-container">
      <PromoBanner/>
      <div className="location-landing">
        <motion.div 
          className="hero-section"
          initial={{ opacity: 0, scale: 0.8, y: 50 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ 
            type: "spring",
            stiffness: 400,
            damping: 30,
            mass: 1.5,
            bounce: 1.55
          }}
        >
          <motion.div className="hero-image-container">
            
          </motion.div>
          <motion.div 
            className="hero-content"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 400,
              damping: 30,
              mass: 1.5,
              bounce: 1.55
            }}
          >
            <h1>Welcome to Unidental {currentLocationData.name}</h1>
          </motion.div>
        </motion.div>

        <motion.section 
          className="welcome-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.6 }}
        >
          <div className="welcome-content">
            <h2>Why Choose Unidental {currentLocationData.name}?</h2>
            <p>{currentLocationData.description}</p>
            <motion.ul
              initial="hidden"
              animate="visible"
              variants={{
                visible: {
                  transition: {
                    staggerChildren: 0.1
                  }
                }
              }}
            >
              {currentLocationData.uniquePoints.map((point, index) => (
                <motion.li
                  key={index}
                  variants={{
                    hidden: { opacity: 0, x: -20 },
                    visible: { opacity: 1, x: 0 }
                  }}
                >
                  {point}
                </motion.li>
              ))}
            </motion.ul>
            <Badges/>
          </div>
          <div className="welcome-form">
            <h2>Book Appointment</h2>
            {isSuccess ? (
              <p className="success-message">
                Thank you for reaching out to us. We will respond to your inquiry as soon as possible.
              </p>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Location:</label>
                  <select
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  >
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Anantapur">Anantapur</option>
                    <option value="Goa">Goa</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    required
                  />
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Additional Information"
                  ></textarea>
                </div>
                {/* <div className="form-group">
                  <Captcha onValidate={handleCaptchaValidate} />
                </div> */}
                <div className="form-group checkbox-group">
                  <label>
                  <input
                    type="checkbox"
                    name="agreement"
                    checked={formData.agreement}
                    onChange={handleChange}
                    required
                  />
                  I agree to allow UNIDENTAL and its partners to contact me via phone, text message, and/or email.
                </label>
                </div>
                <button 
                    type="submit" 
                    className="submit-btn" 
                    disabled={
                      !formData.agreement ||  
                      Object.keys(errors).some(key => errors[key]) ||
                      isSubmitting
                    }
                  >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </form>
            )}
          </div>
        </motion.section>

        <motion.div
          ref={servicesRef}
          className="services-section"
          initial={{ opacity: 0, y: 50 }}
          animate={isServicesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={isServicesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Smile Brighter with Our Comprehensive Care
          </motion.h2>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={isServicesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Services />
          </motion.div>
        </motion.div>

        <motion.div
          ref={reviewsRef}
          className="reviews-section"
          initial={{ opacity: 0, y: 50 }}
          animate={isReviewsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={isReviewsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {currentLocationData.reviews.title}
          </motion.h2>
          <motion.div className="reviews-container">
            {currentLocationData.reviews.items.map((review, index) => (
              <motion.div
                key={index}
                className="review-card"
                initial={{ opacity: 0, y: 30 }}
                animate={isReviewsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                transition={{ duration: 0.6, delay: 0.4 + index * 0.2 }}
              >
                <p className="review-text">{review.text}</p>
                <p className="review-author">- {review.author}</p>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>

        {/* Location Section */}
        <motion.section 
        className="location-section"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="location-content">
          <motion.div
            className="contact-info"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2>Find Us Here</h2>
            <div className="contact-details">
              <div className="contact-item">
                <MapPin className="contact-icon" />
                <p>{currentClinic.address}</p>
              </div>
              <div className="contact-item">
                <Phone className="contact-icon" />
                <a href={`tel:${currentClinic.phone.replace(/[^\d+]/g, '')}`} className="contact-link">
                  {currentClinic.phone}
                </a>
              </div>
              <div className="contact-item">
                <Clock className="contact-icon" />
                <p>10:00am - 8:00 pm</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="map-container"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4 }}
          >
            <iframe
              src={`https://maps.google.com/maps?q=${currentClinic.location.lat},${currentClinic.location.lng}&z=14&output=embed`}
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="map-iframe"
            />
          </motion.div>
        </div>
      </motion.section>
      </div>
      {/* Appointment Form Modal */}
      {isFormOpen && (
          <AppointmentForm 
            closeForm={closeForm} 
            selectedLocation={selectedLocation}
          />
        )}
    </div>
  );
};

export default LocationLanding;